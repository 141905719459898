<template>
  <div class="qcard-container V-alignment" @click="itemClick">
    <img :src="pic" alt="" width="50" height="50" />
    <p>{{pname}}</p>
    <div class="text-desc H-alignment">
      <p>{{pnum}}道题</p>
      <p>￥{{price}}</p>
    </div>
    <!-- <p class="follow-button" @click="followAcc">立即测试</p> -->
  </div>
</template>

<script>
  export default {
    name: 'QCard',
    props: {
      pic: String,
      pname: String,
      pnum: Number,
      price: Number,
    },
    data() {
      return {}
    },
    methods: {
      itemClick() {
        this.$emit('event-card-click', {});
      }
    }
  }
</script>

<style scoped>
  .qcard-container {
    padding: 10px;
    background: #FFF;
    align-items: flex-start;
  }

  .qcard-container > img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .qcard-container > p:nth-child(2) {
    /*background: #FAFAFA;*/
    width: 100%;
    font-weight: bolder;
    margin-top: 10px;
    font-size: .9rem;
    text-align: left;
    /*white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/
  }

  .text-desc {
    width: 100%;
    margin-top: 8px;
    color: #606266;
    font-size: .9rem;
    font-weight: bolder;
    justify-content: space-between;
  }

  .text-desc > p:nth-child(1) {
    font-size: .8rem;
    color: #909399;
  }

  .text-desc > p:nth-child(2) {
    font-size: .9rem;
    color: #F56C6C;
  }
</style>

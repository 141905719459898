<template>
  <div class="home-container V-alignment">
    <Navbar @event-navbar-follow="showDialog = true" />
    <el-carousel indicator-position="none">
      <el-carousel-item v-for="(item, index) in banner" :key="index">
        <el-image :src="item.projectImg" fit="contain" style="width: 100%; height: 100%" @click="go(item)"></el-image>
        <p class="banner-title">Q: {{item.projectName}}</p>
      </el-carousel-item>
    </el-carousel>
    <div class="home-tag">* 热门测试 *</div>
    <div class="main-content">
      <QCard v-for="(item, key) in q" :key="key" :pic="item.projectImg" :pname="item.projectName" :pnum="item.questionNum" :price="item.price"
             @event-card-click="go(item)"/>
    </div>

    <div class="footer-content V-alignment">
      <img src="../../assets/icon/icon-official-account.jpg" alt=""  height="100" />
      <p>河南蓬沸心理咨询有限公司</p>
      <a href="https://beian.miit.gov.cn/#/Integrated/index">豫ICP备2021030452号-1</a>
    </div>

    <!-- shadow dialog -->
    <el-dialog
      title="关注为智心理公众号"
      :visible.sync="showDialog"
      width="80%"
      center>
      <AccCode />
    </el-dialog>
  </div>
</template>

<script>

  // @api
  import { portalList } from 'api/portal'

  // @component
  import Navbar  from 'components/portal/Navbar'
  import QCard   from 'components/portal/QCard'
  import AccCode from 'components/biz/AccCode'
  
  export default {
    name: 'Home',
    props: {},
    components: {
      Navbar, QCard, AccCode
    },
    async mounted(e) {
      const res = await portalList()
      
      this.q = res.resData.slice(3, 13)
      this.banner = res.resData.slice(10, 13)
    },
    methods: {
      go(e) {
        this.$router.push({ name: 'QItem', query: { pid: e.id }});
      }
    },
    computed: {
    },
    data() {
      return {
        q: [],
        banner: [],

        showDialog: false,
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .home-container {
    overflow-x: hidden;
    width: 100vw;
    background: #F5F5F5;
    padding: 10px;
    align-items: flex-start;
  }

  /deep/ .el-carousel {
    width: 100%;
    height: 50vw !important;
    margin-top: 10px;
    border: 1px solid #F0F0F0;
    background: #FEFEFE;
  }

  /deep/ .el-carousel__container {
    height: 100%;
  }

  .banner-title {
    z-index: 10; position: absolute; left: 10px; bottom: 10px;
    font-size: .9rem;
    font-weight: bolder;
    width: 100%;
  }

  .home-tag {
    padding: 6px 0 0 6px;
    display: inline-block;
    margin-top: 16px;
    font-weight: bolder;
  }

  .main-content {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    border-radius: 5px;
    margin-top: 10px;
  }

  .footer-content {
    width: 100vw;
    margin-top: 20px;
    background: #FEFEFE;
    /*color: #FFF;*/
    font-size: .9rem;
    padding: 12px 0 20px 0;
  }

  .footer-content > p:nth-child(2) {
    font-weight: bolder;
    margin-top: 10px;
  }

  .footer-content > a {
    text-decoration: none;
    color: #909399;
    font-size: .8rem;
    margin-top: 6px;
  }

  /*  css for media query */
  @media screen and (min-width: 600px) {
    .main-content {
      grid-template-columns: repeat(3, calc(33.3333% - 8px));
    }

    /deep/ .el-carousel {
      height: 30vw !important;
    }

    /deep/ .el-dialog {
      width: 50% !important;
    }
  }

  @media screen and (min-width: 900px) {
    .main-content {
      grid-template-columns: repeat(4, calc(25% - 8px));
    }

    /deep/ .el-carousel {
      height: 30vw !important;
    }

    /deep/ .el-dialog {
      width: 40% !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .main-content {
      grid-template-columns: repeat(5, calc(20% - 8px));
    }

    /deep/ .el-carousel {
      height: 23vw !important;
    }

    /deep/ .el-dialog {
      width: 20% !important;
    }
  }
</style>
